@font-face {
  font-family: "SeagoeUIBoldItalic";
  src: local("SeagoeUIBoldItalic"),
    url(./assets/fonts/Segoe\ UI\ Bold\ Italic.ttf) format("truetype");
}

@font-face {
  font-family: "SeagoeUIBold";
  src: local("SeagoeUIBold"),
    url(./assets/fonts/Segoe\ UI\ Bold.ttf) format("truetype");
}

@font-face {
  font-family: "SeagoeUIItalic";
  src: local("SeagoeUIItalic"),
    url(./assets/fonts/Segoe\ UI\ Italic.ttf) format("truetype");
}

@font-face {
  font-family: "SeagoeUI";
  src: local("SeagoeUI"), url(./assets/fonts/Segoe\ UI.ttf) format("truetype");
}

* {
  font-family: "SeagoeUI";
}

p {
  margin: 0;
}

body {
  margin: 0;
  font-family: "SeagoeUI";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "SeagoeUI";
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root + .MuiDivider-root {
  margin-top: 0px !important;
}

.rdrCalendarWrapper {
  background: transparent !important;
}

.selected-date-range .rdrStartEdge,
.selected-date-range .rdrEndEdge,
.selected-date-range .rdrInRange,
.selected-date-range .rdrDaySelected {
  background-color: #4ecf47 !important;
}

.rdrDayNumber span {
  color: black !important;
}

.rdrDayDisabled {
  opacity: 0.2 !important;
}
